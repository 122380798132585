@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ehamzs');
  src:  url('fonts/icomoon.eot?ehamzs#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ehamzs') format('truetype'),
    url('fonts/icomoon.woff?ehamzs') format('woff'),
    url('fonts/icomoon.svg?ehamzs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-far-fa-trash-alt:before {
  content: "\e910";
}
.icon-fal-fa-check-circle:before {
  content: "\e911";
}
.icon-fas-fa-spinner:before {
  content: "\e912";
}
.icon-lock-fal:before {
  content: "\e90f";
}
.icon-check-fal:before {
  content: "\e90e";
}
.icon-user:before {
  content: "\e902";
}
.icon-building:before {
  content: "\e903";
}
.icon-envelope:before {
  content: "\e904";
}
.icon-info-circle:before {
  content: "\e905";
}
.icon-file-pdf:before {
  content: "\e906";
}
.icon-clock:before {
  content: "\e907";
}
.icon-pencil:before {
  content: "\e908";
}
.icon-chevron-down:before {
  content: "\e909";
}
.icon-times:before {
  content: "\e90a";
}
.icon-times-circle:before {
  content: "\e90b";
}
.icon-exclamation:before {
  content: "\e90c";
}
.icon-check-circle:before {
  content: "\e90d";
}
.icon-info-circle1:before {
  content: "\e900";
}
.icon-plus-circle:before {
  content: "\e901";
}

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }