@use '@angular/material' as mat;
@import '@angular/material/theming' ;
@include mat.core();

/**************************
 =COLOR PALETTE
 **************************/
$bc-primary: (
  50: #fffae0,
  100: #fff2b3,
  200: #ffe980,
  300: #ffe04d,
  400: #ffd926,
  500: #ffd200,
  600: #ffcd00,
  700: #ffc700,
  800: #ffc100,
  900: #ffb600,
  A100: #ffffff,
  A200: #fffbf2,
  A400: #ffeabf,
  A700: #ffe2a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  )
);
$bc-accent: (
  50: #fff8e2,
  100: #ffedb7,
  200: #ffe187,
  300: #ffd456,
  400: #ffcb32,
  500: #ffc20e,
  600: #ffbc0c,
  700: #ffb40a,
  800: #ffac08,
  900: #ff9f04,
  A100: #ffffff,
  A200: #fffaf2,
  A400: #ffe4bf,
  A700: #ffd9a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  )
);

$bc-warn: (
  50: #feeceb,
  100: #fecfce,
  200: #fdafad,
  300: #fc8e8c,
  400: #fb7674,
  500: #fa5e5b,
  600: #f95653,
  700: #f94c49,
  800: #f84240,
  900: #f6312f,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffd2d2,
  A700: #ffb9b8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  )
);

/**************************
 =THEME CREATION
 **************************/
$bancolombia-theme-primary: mat.define-palette($bc-primary, 500, 200, 900);
$bancolombia-theme-accent: mat.define-palette($bc-accent, 500);
$bancolombia-theme-warn: mat.define-palette($bc-warn, 500, 200, 900);

$bancolombia-theme: mat.define-light-theme(
  $bancolombia-theme-primary,
  $bancolombia-theme-accent,
  $bancolombia-theme-warn
);

/**************************
 =CUSTOM THEME STYLES
 **************************/
@mixin mat-grid-mixin($theme) {
  $primary: map-get(
    $map: $theme,
    $key: primary
  );

  mat-grid-tile {
    background-color: mat.get-color-from-palette($primary, 500);
    color: mat.get-contrast-color-from-palette($primary, 500);
  }
}

@mixin expansion-panel-mixin() {

  mat-expansion-panel-header {

    // Rows
    .mat-content {
      flex-direction  : column;
      justify-content : space-around;
      width           : 100%;
    }

    .mat-expansion-indicator {
      margin: 0.8em auto 0.4em;
    }

    &.progress-panel {
      .mat-expansion-indicator::after {
        height            : 0.2em;
        width             : 0.2em;
        -webkit-transform : rotate(225deg);
        -moz-transform    : rotate(225deg);
        -o-transform      : rotate(225deg);
        transform         : rotate(225deg);
      }
    }

    .mat-expansion-panel-header-description {
      margin: 0;
    }

  }

  mat-list#progress-panel-list {

    padding-top: 0px;
    
    .progress-panel-list-content{
      justify-content: flex-end;
    }

    .mat-subheader {
      width: 97%;
      padding: 0%;
      margin-left: 1.1em;
      height: 25px;
    }    

    #progress-panel-list-item{
      height: 30px;
    }
  
    .mat-list-item-content{
      padding: 0 0 0 16px;
    }
  }
  
}

@mixin form-label-mixin() {

  .mat-form-field-label {
    @include font-light-gray-os-14;
  }

}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  width: 250px !important;
}

.tooltip{
  background: #58595B !important;
  &.tooltip-left::after{
    border-color: transparent transparent transparent #58595B !important;
  }
  &.tooltip-right::after{
    border-color: transparent #58595B transparent transparent  !important;
  }
}