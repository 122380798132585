/**************************
 =RESPONSIVE
 **************************/
 $break-960: 960px;
 $break-768: 768px;
 $break-568: 568px; /*Landscape*/
 $break-480: 480px;
 $break-360: 360px;
 $break-320: 320px;
 
 /**************************
  =STATUS COLORS
  **************************/
 $color-status-00: transparent;
 $color-status-01: #FDDA24;
 $color-status-02: #00448d;
 $color-status-03: #808285;
 $color-status-04: #16c98d;
 $color-status-05: #fa5e5b;
 
 $color-status-06: #f2c700; /* Active Primary */
 $color-status-07: #ffdb33; /* Hover Primary */
 
 $color-status-08: #eaeaea; /* Active Secondary */
 
 /**************************
  =BACKGROUND COLORS
  **************************/
 $color-bg-00: #ffffff;
 $color-bg-01: #fbfbfb;
 $color-bg-02: #f7f7f7;
 $color-bg-03: #e6e7e8;
 $color-bg-04: #d1d3d4;
 $color-bg-05: #747779;
 $color-bg-06: #6d6e71;
 $color-bg-07: #58595b;
 
 /**************************
  =ICONS COLORS
  **************************/
 $color-icon-01: #ffcf01;
 $color-icon-02: #00448d;
 $color-icon-03: #454648;
 $color-icon-04: #ffffff;
 $color-icon-05: #fa5e5b;
 $color-icon-06: #16c98d;
 
 /**************************
  =BRAND COLORS
  **************************/
 $color-brand-01: #ffd200;
 $color-brand-02: #ffffff;
 
 /**************************
  =TEXT COLORS
  **************************/
 $color-text-01: #ffcf01;
 $color-text-02: #00448d;
 $color-text-03: #292929;
 $color-text-04: #808285;
 $color-text-05: #a7a9ac;
 $color-text-06: #d1d3d4;
 $color-text-07: #ffffff;
 $color-text-08: #454648;
 
 /**************************
  =FONTS
  **************************/
 /**** BLUE NUNITO ****/
 /* font/blue/Nunito Bold/32 */
 $font-blue-nt-32-font-size: 32px;
 $font-blue-nt-32-line-height: 32px;
 $font-blue-nt-32-color: $color-text-02;
 $font-blue-nt-32-letter-spacing: -0.6px;
 $font-blue-nt-32-font-weight: 700;
 $font-blue-nt-32-font-family: Nunito, Arial;
 
 @mixin font-blue-nt-32() {
   font-size: $font-blue-nt-32-font-size;
   line-height: $font-blue-nt-32-line-height;
   color: $font-blue-nt-32-color;
   letter-spacing: $font-blue-nt-32-letter-spacing;
   font-weight: $font-blue-nt-32-font-weight;
   font-family: $font-blue-nt-32-font-family;
 }

 /* font/blue/Nunito Bold/28 */
 $font-blue-nt-28-font-size: 28px;
 $font-blue-nt-28-line-height: 30px;
 $font-blue-nt-28-color: $color-text-02;
 $font-blue-nt-28-letter-spacing: -0.6px;
 $font-blue-nt-28-font-weight: 700;
 $font-blue-nt-28-font-family: Nunito, Arial;
 
 @mixin font-blue-nt-28() {
   font-size: $font-blue-nt-28-font-size;
   line-height: $font-blue-nt-28-line-height;
   color: $font-blue-nt-28-color;
   letter-spacing: $font-blue-nt-28-letter-spacing;
   font-weight: $font-blue-nt-28-font-weight;
   font-family: $font-blue-nt-28-font-family;
 }

 /* font/blue/Nunito Bold/24 */
 $font-blue-nt-24-font-size: 24px;
 $font-blue-nt-24-line-height: 28px;
 $font-blue-nt-24-color: $color-text-02;
 $font-blue-nt-24-letter-spacing: -0.6px;
 $font-blue-nt-24-font-weight: 700;
 $font-blue-nt-24-font-family: Nunito, Arial;
 
 @mixin font-blue-nt-24() {
   font-size: $font-blue-nt-24-font-size;
   line-height: $font-blue-nt-24-line-height;
   color: $font-blue-nt-24-color;
   letter-spacing: $font-blue-nt-24-letter-spacing;
   font-weight: $font-blue-nt-24-font-weight;
   font-family: $font-blue-nt-24-font-family;
 }
 
 /* font/blue/Nunito Bold/20 */
 $font-blue-nt-20-font-size: 20px;
 $font-blue-nt-20-line-height: 24px;
 $font-blue-nt-20-color: $color-text-02;
 $font-blue-nt-20-letter-spacing: -0.38px;
 $font-blue-nt-20-font-weight: 700;
 $font-blue-nt-20-font-family: Nunito, Arial;
 
 @mixin font-blue-nt-20() {
   font-size: $font-blue-nt-20-font-size;
   line-height: $font-blue-nt-20-line-height;
   color: $font-blue-nt-20-color;
   letter-spacing: $font-blue-nt-20-letter-spacing;
   font-weight: $font-blue-nt-20-font-weight;
   font-family: $font-blue-nt-20-font-family;
 }
 
  /* font/blue/Nunito Bold/18 */
  $font-blue-nt-18-font-size: 18px;
  $font-blue-nt-18-line-height: 20px;
  $font-blue-nt-18-color: $color-text-02;
  $font-blue-nt-18-letter-spacing: -0.3px;
  $font-blue-nt-18-font-weight: 700;
  $font-blue-nt-18-font-family: Nunito, Arial;
  
  @mixin font-blue-nt-18() {
    font-size: $font-blue-nt-18-font-size;
    line-height: $font-blue-nt-18-line-height;
    color: $font-blue-nt-18-color;
    letter-spacing: $font-blue-nt-18-letter-spacing;
    font-weight: $font-blue-nt-18-font-weight;
    font-family: $font-blue-nt-18-font-family;
  }
 
 /**** BLUE OPENSANS ****/
 /* font/blue/OpenSans SemiBold/16 */
 $font-blue-os-sm-16-font-size: 16px;
 $font-blue-os-sm-16-line-height: 24px;
 $font-blue-os-sm-16-color: $color-text-02;
 $font-blue-os-sm-16-letter-spacing: -0.3px;
 $font-blue-os-sm-16-font-weight: 600;
 $font-blue-os-sm-16-font-family: Open Sans, Arial;
 
 @mixin font-blue-os-sm-16() {
   font-size: $font-blue-os-sm-16-font-size;
   line-height: $font-blue-os-sm-16-line-height;
   color: $font-blue-os-sm-16-color;
   letter-spacing: $font-blue-os-sm-16-letter-spacing;
   font-weight: $font-blue-os-sm-16-font-weight;
   font-family: $font-blue-os-sm-16-font-family;
 }
 
 /**** WHITE NUNITO ****/
 /* font/white/Nunito ExtraBold/96 */
 $font-white-nt-96-font-size: 96px;
 $font-white-nt-96-line-height: 90px;
 $font-white-nt-96-color: $color-text-07;
 $font-white-nt-96-letter-spacing: -0.6px;
 $font-white-nt-96-font-weight: 800;
 $font-white-nt-96-font-family: Nunito, Arial;
 
 @mixin font-white-nt-96() {
   font-size: $font-white-nt-96-font-size;
   line-height: $font-white-nt-96-line-height;
   color: $font-white-nt-96-color;
   letter-spacing: $font-white-nt-96-letter-spacing;
   font-weight: $font-white-nt-96-font-weight;
   font-family: $font-white-nt-96-font-family;
 }
 
 /* font/white/Nunito Bold/18 */
 $font-white-nt-18-font-size: 18px;
 $font-white-nt-18-line-height: 20px;
 $font-white-nt-18-color: $color-text-07;
 $font-white-nt-18-letter-spacing: -0.3px;
 $font-white-nt-18-font-weight: 700;
 $font-white-nt-18-font-family: Nunito;
 
 @mixin font-white-nt-18() {
   font-size: $font-white-nt-18-font-size;
   line-height: $font-white-nt-18-line-height;
   color: $font-white-nt-18-color;
   letter-spacing: $font-white-nt-18-letter-spacing;
   font-weight: $font-white-nt-18-font-weight;
   font-family: $font-white-nt-18-font-family;
 }
 
 /**** WHITE OPENSANS ****/
 /* font/white/OpenSans SemiBold/16 */
 $font-white-os-sm-16-font-size: 16px;
 $font-white-os-sm-16-line-height: 24px;
 $font-white-os-sm-16-color: $color-text-07;
 $font-white-os-sm-16-letter-spacing: -0.3px;
 $font-white-os-sm-16-font-weight: 600;
 $font-white-os-sm-16-font-family: Open Sans, Arial;
 
 @mixin font-white-os-sm-16() {
   font-size: $font-white-os-sm-16-font-size;
   line-height: $font-white-os-sm-16-line-height;
   color: $font-white-os-sm-16-color;
   letter-spacing: $font-white-os-sm-16-letter-spacing;
   font-weight: $font-white-os-sm-16-font-weight;
   font-family: $font-white-os-sm-16-font-family;
 }
 
 /* font/white/OpenSans Regular/16 */
 $font-white-os-16-font-size: 16px;
 $font-white-os-16-line-height: 24px;
 $font-white-os-16-color: $color-text-07;
 $font-white-os-16-letter-spacing: -0.3px;
 $font-white-os-16-font-weight: 400;
 $font-white-os-16-font-family: Open Sans, Arial;
 
 @mixin font-white-os-16() {
   font-size: $font-white-os-16-font-size;
   line-height: $font-white-os-16-line-height;
   color: $font-white-os-16-color;
   letter-spacing: $font-white-os-16-letter-spacing;
   font-weight: $font-white-os-16-font-weight;
   font-family: $font-white-os-16-font-family;
 }
 
 /**** DARK GRAY NUNITO ****/
 /* font/dark gray/Nunito ExtraBold/56 */
 $font-dark-nt-56-font-size: 56px;
 $font-dark-nt-56-line-height: 50px;
 $font-dark-nt-56-color: $color-text-03;
 $font-dark-nt-56-letter-spacing: -0.47px;
 $font-dark-nt-56-font-weight: 800;
 $font-dark-nt-56-font-family: Nunito, Arial;
 
 @mixin font-dark-nt-56() {
   font-size: $font-dark-nt-56-font-size;
   line-height: $font-dark-nt-56-line-height;
   color: $font-dark-nt-56-color;
   letter-spacing: $font-dark-nt-56-letter-spacing;
   font-weight: $font-dark-nt-56-font-weight;
   font-family: $font-dark-nt-56-font-family;
 }

  /* font/dark gray/Nunito ExtraBold/50 */
  $font-dark-nt-50-font-size: 50px;
  $font-dark-nt-50-line-height: 50px;
  $font-dark-nt-50-color: $color-text-03;
  $font-dark-nt-50-letter-spacing: -0.47px;
  $font-dark-nt-50-font-weight: 800;
  $font-dark-nt-50-font-family: Nunito, Arial;
  
  @mixin font-dark-nt-50() {
    font-size: $font-dark-nt-50-font-size;
    line-height: $font-dark-nt-50-line-height;
    color: $font-dark-nt-50-color;
    letter-spacing: $font-dark-nt-50-letter-spacing;
    font-weight: $font-dark-nt-50-font-weight;
    font-family: $font-dark-nt-50-font-family;
  }
 
 /* font/dark gray/Nunito Bold/40 */
 $font-dark-nt-40-font-size: 40px;
 $font-dark-nt-40-line-height: 40px;
 $font-dark-nt-40-color: $color-text-03;
 $font-dark-nt-40-letter-spacing: -0.6px;
 $font-dark-nt-40-font-weight: 700;
 $font-dark-nt-40-font-family: Nunito, Arial;
 
 @mixin font-dark-nt-40() {
   font-size: $font-dark-nt-40-font-size;
   line-height: $font-dark-nt-40-line-height;
   color: $font-dark-nt-40-color;
   letter-spacing: $font-dark-nt-40-letter-spacing;
   font-weight: $font-dark-nt-40-font-weight;
   font-family: $font-dark-nt-40-font-family;
 }
 
 /* font/dark gray/Nunito Bold/32 */
 $font-dark-nt-32-font-size: 32px;
 $font-dark-nt-32-line-height: 32px;
 $font-dark-nt-32-color: $color-text-03;
 $font-dark-nt-32-letter-spacing: -0.6px;
 $font-dark-nt-32-font-weight: 700;
 $font-dark-nt-32-font-family: Nunito, Arial;
 
 @mixin font-dark-nt-32() {
   font-size: $font-dark-nt-32-font-size;
   line-height: $font-dark-nt-32-line-height;
   color: $font-dark-nt-32-color;
   letter-spacing: $font-dark-nt-32-letter-spacing;
   font-weight: $font-dark-nt-32-font-weight;
   font-family: $font-dark-nt-32-font-family;
 }
 
 /* font/dark gray/Nunito Bold/28 */
 $font-dark-nt-28-font-size: 28px;
 $font-dark-nt-28-line-height: 30px;
 $font-dark-nt-28-color: $color-text-03;
 $font-dark-nt-28-letter-spacing: -0.6px;
 $font-dark-nt-28-font-weight: 700;
 $font-dark-nt-28-font-family: Nunito, Arial;
 
 @mixin font-dark-nt-28() {
   font-size: $font-dark-nt-28-font-size;
   line-height: $font-dark-nt-28-line-height;
   color: $font-dark-nt-28-color;
   letter-spacing: $font-dark-nt-28-letter-spacing;
   font-weight: $font-dark-nt-28-font-weight;
   font-family: $font-dark-nt-28-font-family;
 }
 
 /* font/dark gray/Nunito Bold/24 */
 $font-dark-nt-24-font-size: 24px;
 $font-dark-nt-24-line-height: 26px;
 $font-dark-nt-24-color: $color-text-03;
 $font-dark-nt-24-letter-spacing: -0.45px;
 $font-dark-nt-24-font-weight: 700;
 $font-dark-nt-24-font-family: Nunito, Arial;
 
 @mixin font-dark-nt-24() {
   font-size: $font-dark-nt-24-font-size;
   line-height: $font-dark-nt-24-line-height;
   color: $font-dark-nt-24-color;
   letter-spacing: $font-dark-nt-24-letter-spacing;
   font-weight: $font-dark-nt-24-font-weight;
   font-family: $font-dark-nt-24-font-family;
 }
 
 /* font/dark gray/Nunito Bold/20 */
 $font-dark-nt-20-font-size: 20px;
 $font-dark-nt-20-line-height: 24px;
 $font-dark-nt-20-color: $color-text-03;
 $font-dark-nt-20-letter-spacing: -0.38px;
 $font-dark-nt-20-font-weight: 700;
 $font-dark-nt-20-font-family: Nunito, Arial;
 
 @mixin font-dark-nt-20() {
   font-size: $font-dark-nt-20-font-size;
   line-height: $font-dark-nt-20-line-height;
   color: $font-dark-nt-20-color;
   letter-spacing: $font-dark-nt-20-letter-spacing;
   font-weight: $font-dark-nt-20-font-weight;
   font-family: $font-dark-nt-20-font-family;
 }
 
 /* font/dark gray/Nunito Bold/18 */
 $font-dark-nt-18-font-size: 18px;
 $font-dark-nt-18-line-height: 20px;
 $font-dark-nt-18-color: $color-text-03;
 $font-dark-nt-18-letter-spacing: -0.3px;
 $font-dark-nt-18-font-weight: 700;
 $font-dark-nt-18-font-family: Nunito, Arial;
 
 @mixin font-dark-nt-18() {
   font-size: $font-dark-nt-18-font-size;
   line-height: $font-dark-nt-18-line-height;
   color: $font-dark-nt-18-color;
   letter-spacing: $font-dark-nt-18-letter-spacing;
   font-weight: $font-dark-nt-18-font-weight;
   font-family: $font-dark-nt-18-font-family;
 }

  /* font/dark gray/Nunito Bold/16 */
  $font-dark-nt-16-font-size: 16px;
  $font-dark-nt-16-line-height: 18px;
  $font-dark-nt-16-color: $color-text-03;
  $font-dark-nt-16-letter-spacing: -0.3px;
  $font-dark-nt-16-font-weight: 700;
  $font-dark-nt-16-font-family: Nunito, Arial;
  
  @mixin font-dark-nt-16() {
    font-size: $font-dark-nt-16-font-size;
    line-height: $font-dark-nt-16-line-height;
    color: $font-dark-nt-16-color;
    letter-spacing: $font-dark-nt-16-letter-spacing;
    font-weight: $font-dark-nt-16-font-weight;
    font-family: $font-dark-nt-16-font-family;
  }

  /* font/dark gray/Nunito Bold/14 */
  $font-dark-nt-14-font-size: 14px;
  $font-dark-nt-14-line-height: 16px;
  $font-dark-nt-14-color: $color-text-03;
  $font-dark-nt-14-letter-spacing: -0.3px;
  $font-dark-nt-14-font-weight: 700;
  $font-dark-nt-14-font-family: Nunito, Arial;
  
  @mixin font-dark-nt-14() {
    font-size: $font-dark-nt-14-font-size;
    line-height: $font-dark-nt-14-line-height;
    color: $font-dark-nt-14-color;
    letter-spacing: $font-dark-nt-14-letter-spacing;
    font-weight: $font-dark-nt-14-font-weight;
    font-family: $font-dark-nt-14-font-family;
  }
 
 /**** DARK GRAY OPENSANS ****/
 /* font/dark gray/OpenSans Regular/16 */
 $font-dark-os-16-font-size: 16px;
 $font-dark-os-16-line-height: 24px;
 $font-dark-os-16-color: $color-text-03;
 $font-dark-os-16-letter-spacing: -0.3px;
 $font-dark-os-16-font-weight: 400;
 $font-dark-os-16-font-family: Open Sans, Arial;
 
 @mixin font-dark-os-16() {
   font-size: $font-dark-os-16-font-size;
   line-height: $font-dark-os-16-line-height;
   color: $font-dark-os-16-color;
   letter-spacing: $font-dark-os-16-letter-spacing;
   font-weight: $font-dark-os-16-font-weight;
   font-family: $font-dark-os-16-font-family;
 }
 
 /* font/dark gray/OpenSans Regular/14 */
 $font-dark-os-14-font-size: 14px;
 $font-dark-os-14-line-height: 18px;
 $font-dark-os-14-color: $color-text-03;
 $font-dark-os-14-letter-spacing: -0.2px;
 $font-dark-os-14-font-weight: 400;
 $font-dark-os-14-font-family: Open Sans, Arial;
 
 @mixin font-dark-os-14() {
   font-size: $font-dark-os-14-font-size;
   line-height: $font-dark-os-14-line-height;
   color: $font-dark-os-14-color;
   letter-spacing: $font-dark-os-14-letter-spacing;
   font-weight: $font-dark-os-14-font-weight;
   font-family: $font-dark-os-14-font-family;
 }
 
 /* font/dark gray/OpenSans SemiBold/16 */
 $font-dark-os-sm-16-font-size: 16px;
 $font-dark-os-sm-16-line-height: 24px;
 $font-dark-os-sm-16-color: $color-text-03;
 $font-dark-os-sm-16-letter-spacing: -0.3px;
 $font-dark-os-sm-16-font-weight: 600;
 $font-dark-os-sm-16-font-family: Open Sans, Arial;
 
 @mixin font-dark-os-sm-16() {
   font-size: $font-dark-os-sm-16-font-size;
   line-height: $font-dark-os-sm-16-line-height;
   color: $font-dark-os-sm-16-color;
   letter-spacing: $font-dark-os-sm-16-letter-spacing;
   font-weight: $font-dark-os-sm-16-font-weight;
   font-family: $font-dark-os-sm-16-font-family;
 }
 
 /**** LIGHT GRAY NUNITO ****/
 /* font/light gray/Nunito Bold/28 */
 $font-light-gray-nt-28-font-size: 28px;
 $font-light-gray-nt-28-line-height: 30px;
 $font-light-gray-nt-28-color: $color-text-04;
 $font-light-gray-nt-28-letter-spacing: -0.6px;
 $font-light-gray-nt-28-font-weight: 700;
 $font-light-gray-nt-28-font-family: Nunito, Arial;
 
 @mixin font-light-gray-nt-28() {
   font-size: $font-light-gray-nt-28-font-size;
   line-height: $font-light-gray-nt-28-line-height;
   color: $font-light-gray-nt-28-color;
   letter-spacing: $font-light-gray-nt-28-letter-spacing;
   font-weight: $font-light-gray-nt-28-font-weight;
   font-family: $font-light-gray-nt-28-font-family;
 }
 
 /* font/light gray/Nunito Bold/24 */
 $font-light-gray-nt-24-font-size: 24px;
 $font-light-gray-nt-24-line-height: 26px;
 $font-light-gray-nt-24-color: $color-text-04;
 $font-light-gray-nt-24-letter-spacing: -0.6px;
 $font-light-gray-nt-24-font-weight: 700;
 $font-light-gray-nt-24-font-family: Nunito, Arial;
 
 @mixin font-light-gray-nt-24() {
   font-size: $font-light-gray-nt-24-font-size;
   line-height: $font-light-gray-nt-24-line-height;
   color: $font-light-gray-nt-24-color;
   letter-spacing: $font-light-gray-nt-24-letter-spacing;
   font-weight: $font-light-gray-nt-24-font-weight;
   font-family: $font-light-gray-nt-24-font-family;
 }
 
 /* font/light gray/Nunito Bold/18 */
 $font-light-gray-nt-18-font-size: 18px;
 $font-light-gray-nt-18-line-height: 20px;
 $font-light-gray-nt-18-color: $color-text-04;
 $font-light-gray-nt-18-letter-spacing: -0.3px;
 $font-light-gray-nt-18-font-weight: 700;
 $font-light-gray-nt-18-font-family: Nunito, Arial;
 
 @mixin font-light-gray-nt-18() {
   font-size: $font-light-gray-nt-18-font-size;
   line-height: $font-light-gray-nt-18-line-height;
   color: $font-light-gray-nt-18-color;
   letter-spacing: $font-light-gray-nt-18-letter-spacing;
   font-weight: $font-light-gray-nt-18-font-weight;
   font-family: $font-light-gray-nt-18-font-family;
 }
 
 /* font/light gray/Nunito Bold/20 */
 $font-light-gray-nt-20-font-size: 20px;
 $font-light-gray-nt-20-line-height: 24px;
 $font-light-gray-nt-20-color: $color-text-04;
 $font-light-gray-nt-20-letter-spacing: -0.38px;
 $font-light-gray-nt-20-font-weight: 700;
 $font-light-gray-nt-20-font-family: Nunito, Arial;
 
 @mixin font-light-gray-nt-20() {
   font-size: $font-light-gray-nt-20-font-size;
   line-height: $font-light-gray-nt-20-line-height;
   color: $font-light-gray-nt-20-color;
   letter-spacing: $font-light-gray-nt-20-letter-spacing;
   font-weight: $font-light-gray-nt-20-font-weight;
   font-family: $font-light-gray-nt-20-font-family;
 }

 /* font/light gray/Nunito Bold/20 */
 $font-light-gray-nt-16-font-size: 16px;
 $font-light-gray-nt-16-line-height: 20px;
 $font-light-gray-nt-16-color: $color-text-04;
 $font-light-gray-nt-16-letter-spacing: -0.38px;
 $font-light-gray-nt-16-font-weight: 400;
 $font-light-gray-nt-16-font-family: Nunito, Arial;
 
 @mixin font-light-gray-nt-16() {
   font-size: $font-light-gray-nt-16-font-size;
   line-height: $font-light-gray-nt-16-line-height;
   color: $font-light-gray-nt-16-color;
   letter-spacing: $font-light-gray-nt-16-letter-spacing;
   font-weight: $font-light-gray-nt-16-font-weight;
   font-family: $font-light-gray-nt-16-font-family;
 }
 
 /**** LIGHT GRAY OPENSANS ****/
 /* font/light gray/OpenSans Regular/18 */
 $font-light-gray-os-18-font-size: 18px;
 $font-light-gray-os-18-line-height: 24px;
 $font-light-gray-os-18-color: $color-text-04;
 $font-light-gray-os-18-letter-spacing: -0.3px;
 $font-light-gray-os-18-font-weight: 400;
 $font-light-gray-os-18-font-family: Open Sans, Arial;
 
 @mixin font-light-gray-os-18() {
   font-size: $font-light-gray-os-18-font-size;
   line-height: $font-light-gray-os-18-line-height;
   color: $font-light-gray-os-18-color;
   letter-spacing: $font-light-gray-os-18-letter-spacing;
   font-weight: $font-light-gray-os-18-font-weight;
   font-family: $font-light-gray-os-18-font-family;
 }
 
 /* font/light gray/OpenSans Regular/16 */
 $font-light-gray-os-16-font-size: 16px;
 $font-light-gray-os-16-line-height: 24px;
 $font-light-gray-os-16-color: $color-text-04;
 $font-light-gray-os-16-letter-spacing: -0.3px;
 $font-light-gray-os-16-font-weight: 400;
 $font-light-gray-os-16-font-family: Open Sans, Arial;
 
 @mixin font-light-gray-os-16() {
   font-size: $font-light-gray-os-16-font-size;
   line-height: $font-light-gray-os-16-line-height;
   color: $font-light-gray-os-16-color;
   letter-spacing: $font-light-gray-os-16-letter-spacing;
   font-weight: $font-light-gray-os-16-font-weight;
   font-family: $font-light-gray-os-16-font-family;
 }
 
 /* font/light gray/OpenSans Regular/14 */
 $font-light-gray-os-14-font-size: 14px;
 $font-light-gray-os-14-line-height: 18px;
 $font-light-gray-os-14-color: $color-text-04;
 $font-light-gray-os-14-letter-spacing: -0.2px;
 $font-light-gray-os-14-font-weight: 400;
 $font-light-gray-os-14-font-family: Open Sans, Arial;
 
 @mixin font-light-gray-os-14() {
   font-size: $font-light-gray-os-14-font-size;
   line-height: $font-light-gray-os-14-line-height;
   color: $font-light-gray-os-14-color;
   letter-spacing: $font-light-gray-os-14-letter-spacing;
   font-weight: $font-light-gray-os-14-font-weight;
   font-family: $font-light-gray-os-14-font-family;
 }
 
 /* font/light gray/OpenSans Regular/12 */
 $font-light-gray-os-12-font-size: 12px;
 $font-light-gray-os-12-line-height: 18px;
 $font-light-gray-os-12-color: $color-text-04;
 $font-light-gray-os-12-letter-spacing: -0.17px;
 $font-light-gray-os-12-font-weight: 400;
 $font-light-gray-os-12-font-family: Open Sans, Arial;
 
 @mixin font-light-gray-os-12() {
   font-size: $font-light-gray-os-12-font-size;
   line-height: $font-light-gray-os-12-line-height;
   color: $font-light-gray-os-12-color;
   letter-spacing: $font-light-gray-os-12-letter-spacing;
   font-weight: $font-light-gray-os-12-font-weight;
   font-family: $font-light-gray-os-12-font-family;
 }
 
 /* font/light gray/OpenSans SemiBold/16 */
 $font-light-gray-os-sm-16-font-size: 16px;
 $font-light-gray-os-sm-16-line-height: 24px;
 $font-light-gray-os-sm-16-color: $color-text-04;
 $font-light-gray-os-sm-16-letter-spacing: -0.3px;
 $font-light-gray-os-sm-16-font-weight: 600;
 $font-light-gray-os-sm-16-font-family: Open Sans, Arial;
 
 @mixin font-light-gray-os-sm-16() {
   font-size: $font-light-gray-os-sm-16-font-size;
   line-height: $font-light-gray-os-sm-16-line-height;
   color: $font-light-gray-os-sm-16-color;
   letter-spacing: $font-light-gray-os-sm-16-letter-spacing;
   font-weight: $font-light-gray-os-sm-16-font-weight;
   font-family: $font-light-gray-os-sm-16-font-family;
 }
 
 /**************************
  =SHADOW
  **************************/
 @mixin shadow-01 () {
   -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
   -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
   box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
 }
 
 @mixin shadow-02 () {
   -webkit-box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.5);
   -moz-box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.5);
   box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.5);
 }
 
 /**************************
  =BORDER RADIUS
  **************************/
 @mixin border-radius-10-tl () {
   -webkit-border-radius: 10px 0px 0px 10px;
   -moz-border-radius: 10px 0px 0px 10px;
   border-radius: 10px 0px 0px 10px;
   
 }
 @mixin border-radius-5 () {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
 
 @mixin border-radius-25 () {
   -webkit-border-radius: 25px;
   -moz-border-radius: 25px;
   border-radius: 25px;
 }
 
 /**************************
  =BUTTONS
  **************************/
 /* btn-primary */
 @mixin btn-primary () {
   @include font-dark-nt-20;
   @include border-radius-25;
   background: $color-status-01;
   text-transform: uppercase;
   padding: 10px 40px;
 }
 
 /* btn-secondary */
 @mixin btn-secondary () {
   @include font-blue-nt-20;
   @include border-radius-25;
   border: 1px solid #000;
   background: $color-status-00;
   text-transform: uppercase;
   padding: 10px 40px;
 }