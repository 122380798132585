@use '@angular/material' as mat;
@import 'assets/icons/style.css';
@import 'bancolombia-theme.scss';
@import 'styles/_variables.scss';


.bancolombia-theme {
  $theme: $bancolombia-theme;
  @include mat.all-component-themes($theme);
  @include mat-grid-mixin($theme);
  @include expansion-panel-mixin();
  @import 'styles/_global-styles.scss';
}


body {
  font-family: Nunito;
  margin: 0;
  margin: 0;
  padding: 0;
  background-color: #F7F7F7;
}

.container {
  margin: 0;
  padding: 1em;
}

:focus {
  outline: none;
}

