.bg {
  background: $color-text-03;
  margin: 10px 0px;
  padding: 15px;
}

a {
  display: block;
}
body {
  overflow-x: hidden;
}

/**************************
 =BUTTONS
 **************************/
.btn-primary {
  @include btn-primary;
  &:active {
    background-color: $color-status-06;
  }
  &:hover {
    background: $color-status-07;
    color: $color-text-08;
    @include shadow-01;
  }
  &:focus {
    background: $color-status-06;
  }
  &.mat-button[disabled] {
    background: $color-status-03;
  }
}

.btn-secondary {
  border: 1px solid  #000;

  @include btn-secondary;
  &:active {
    background-color: $color-status-08;
  }
  &:hover {
    background: $color-status-00;
    @include shadow-01;
  }
  &.mat-button[disabled] {
    border: 1px solid $color-bg-04;
    color: $color-text-06;
    &:hover {
      box-shadow: none;
    }
  }

}

/**************************
 =FONTS
 **************************/
.font-white-nt-96 {
  @include font-white-nt-96;
}
.font-white-nt-18 {
  @include font-white-nt-18;
}
.font-dark-nt-56 {
  @include font-dark-nt-56;
}
.font-dark-nt-50 {
  @include font-dark-nt-50;
}
.font-dark-nt-40 {
  @include font-dark-nt-40;
}
.font-dark-nt-32 {
  @include font-dark-nt-32;
}
.font-dark-nt-28 {
  @include font-dark-nt-28;
}
.font-dark-nt-24 {
  @include font-dark-nt-24;
}
.font-dark-nt-20 {
  @include font-dark-nt-20;
}
.font-dark-nt-18 {
  @include font-dark-nt-18;
}
.font-dark-nt-16 {
  @include font-dark-nt-16;
}
.font-dark-nt-14 {
  @include font-dark-nt-14;
}
.font-light-gray-nt-28 {
  @include font-light-gray-nt-28;
}
.font-light-gray-nt-24 {
  @include font-light-gray-nt-24;
}
.font-light-gray-nt-20 {
  @include font-light-gray-nt-20;
}
.font-light-gray-nt-18 {
  @include font-light-gray-nt-18;
}
.font-light-gray-nt-16 {
  @include font-light-gray-nt-16;
}
.font-white-os-sm-16 {
  @include font-white-os-sm-16;
}
.font-white-os-16 {
  @include font-white-os-16;
}
.font-blue-nt-32 {
  @include font-blue-nt-32;
}
.font-blue-nt-28 {
  @include font-blue-nt-28;
}
.font-blue-nt-24 {
  @include font-blue-nt-24;
}
.font-blue-nt-20 {
  @include font-blue-nt-20;
}
.font-blue-nt-18 {
  @include font-blue-nt-18;
}
.font-blue-os-sm-16 {
  @include font-blue-os-sm-16;
}
.font-dark-os-sm-16 {
  @include font-dark-os-sm-16;
}
.font-dark-os-16 {
  @include font-dark-os-16;
}
.font-dark-os-14 {
  @include font-dark-os-14;
}
.font-light-gray-os-16 {
  @include font-light-gray-os-16;
}
.font-light-gray-os-sm-16 {
  @include font-light-gray-os-sm-16;
}
.font-light-gray-os-14 {
  @include font-light-gray-os-14;
}
.font-light-gray-os-12 {
  @include font-light-gray-os-12;
}

/**************************
 =MODALS
**************************/

.full-width-dialog {
  .mat-dialog-container {
    width: 100% !important;
  }
}
.mat-dialog-container {
  margin: 0 auto;
  width: 100%;
  h2 {
    @include font-dark-nt-24;
  }
  p {
    @include font-dark-os-16;
    text-align: center;
    span {
      @include font-light-gray-os-14;
    }
  }
  svg {
    height: 32px;
    width: 32px;
    &.fa-info-circle {
      color: $color-icon-02;
    }
    &.fa-times-circle {
      color: $color-icon-05;
    }
  }
  .mat-dialog-actions {
    margin-bottom: inherit;
  }
}

/**************************
 =PROGRESS BAR
 **************************/
.mat-progress-bar {
  @include border-radius-25;
  height: 8px;
  .mat-progress-bar-fill::after {
    @include border-radius-25;
  }
  .mat-progress-bar-buffer {
    background-color: $color-bg-05;
  }
}

/**************************
 =ALERT
 **************************/
.cdk-overlay-container {
  .cdk-overlay-pane {
    /*width: 100%;*/
  }
  .mat-snack-bar-container {
    background: $color-bg-00;
    min-width: 90vw;
    p {
      display: flex;
      margin: 0px;
      span + span {
        @include font-dark-os-sm-16;
        margin-top: 5px;
      }
    }
    svg {
      height: 32px;
      margin-right: 15px;
      width: 32px;
      &.success {
        color: $color-icon-06;
      }
      &.info {
        color: $color-icon-02;
      }
      &.warn {
        color: $color-icon-01;
      }
      &.danger {
        color: $color-icon-05;
      }
    }
    .close {
      svg {
        color: $color-icon-03;
        cursor: pointer;
        margin: 0px 0px 0px 20px;
        height: 22px;
        width: 22px;
      }
    }
  }
}

/**************************
 =FORM - PERSONAL INFO
 **************************/
 .info-form {
  width: 100%;
  section {
          width: 100vw;
          opacity: 1;
          margin-bottom: 400px;
          width: 100%;
      .mat-form-field {
          max-width: 480px;
      }
      h2 {
          @include font-dark-nt-50;
          margin: 0px 0px 20px 0px;
          @media screen and (max-width: $break-568) {
              font-size: 32px;
              line-height: 32px;
          }
      }
      > span {
          @include font-light-gray-os-14;
          margin-bottom: 16px;
          @media screen and (max-width: $break-568) {
              text-transform: uppercase;
              margin-bottom: 5px;
          }
      }
      small {
          @include font-light-gray-os-14;
              margin: 15px auto 0px;
          @media screen and (max-width: $break-768) {
              display: none;
          }
      }
      mat-form-field {
          width: 100%;
          .mat-form-field-infix {
              padding-left: 10px;
              .mat-input-element {
                  @include font-dark-os-16;
              }
              .mat-form-field-label-wrapper {
                  mat-label {
                      @include font-light-gray-os-18;
                  }
              }
          }
          &.icon-left {
              .mat-form-field-infix {
                  padding-left: 45px;
              }
          }
      }
      .mat-form-field-prefix {
          position: absolute;
          bottom: 26px;
          left: 15px;
          svg {
              color: $color-icon-03;
              height: 24px;
              width: 24px;
          }
      }
      .mat-form-field-suffix {
          svg {
              color: $color-icon-03;
              height: 18px;
              width: 18px;
          }
      }
      .mat-button {
          margin: 50px auto 0px;
          padding: 10px 70px;
          @media screen and (max-width: $break-480) {
              width: 88%;
          }
      }
  }
}
.mat-select-arrow-wrapper {
  visibility: hidden;
}

/**************************
 =GENERAL FORM
**************************/
.mat-form-field.mat-focused {
    .mat-form-field-label {
        color: #808285;
    }
}


/**************************
 =RADIO FORM
**************************/
mat-radio-button {
  .mat-radio-container {
    .mat-radio-outer-circle {
      border-color: $color-text-05;
      height: 23px;
      width: 23px;
    }
    .mat-radio-inner-circle {
      height: 15px;
      left: 4px;
      top: 4px;
      width: 15px;
    }
  }
  .mat-radio-label-content {
    @include font-dark-os-16;
  }
  &.mat-radio-checked {
    .mat-radio-outer-circle {
      border-color: $color-status-01;
      background: $color-status-01;
    }
  }
  &.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: transparent !important;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $color-status-01;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $color-icon-03;
}


@mixin expansion-panel-mixin() {

  mat-expansion-panel-header {

    // Rows
    .mat-content {
      flex-direction  : column;
      justify-content : space-around;
      width           : 100%;
    }

    .mat-expansion-indicator {
      margin: 0.3em auto 0.4em;
    }

    &.progress-panel {
      .mat-expansion-indicator::after {
        height            : 0.2em;
        width             : 0.2em;
        -webkit-transform : rotate(225deg);
        -moz-transform    : rotate(225deg);
        -o-transform      : rotate(225deg);
        transform         : rotate(225deg);
      }
    }

    .mat-expansion-panel-header-description {
      margin: 0;
    }

  }

  mat-list#progress-panel-list {
    padding-top: 0px;
    .progress-panel-list-content{
      justify-content: flex-end;
    }
    .mat-subheader {
      width: 97%;
      padding: 0%;
      height: 25px;
    }
    #progress-panel-list-item{
      height: 24px;
    }
  }

}

/**************************
 =BANNER DESKTOP
**************************/
.banner-desktop {
  position: fixed;
  right: 0;
  top: 20%;
  width: 294px;
  z-index: 1;
  h2 {
    @include font-dark-nt-24;
    margin: 5px 0px 15px 0px;
  }
  h3 {
    @include font-dark-os-14;
    margin: 0px;
  }
  img {
    @include border-radius-10-tl;
    // @include shadow-02;
    width: 100%;
  }
}
.banner-mobile {
  display: flex;
  flex-flow: row no-wrap;
  margin: 0px;
  position: static;
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
    width: 100%;
    h2 {
      margin: 0;
      @include font-dark-os-14;
    }
    h3 {
      margin: 0;
      @include font-light-gray-os-12;
    }
  }
  img {
    // @include shadow-02;
      // box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
    @include border-radius-5;
    height: 40px;
    order: -1;
    width: 62px;
  }

}

.mat-step-label {
  font-size: 20px;
  line-height: 24px;
  color: #0000 !important;
  letter-spacing: -0.38px;
  font-weight: 700;
  font-family: Nunito, Arial;
  text-overflow: initial;
  white-space: normal;
}

.sucess {
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: $color-status-04;
    color: #fff;
  }
}

@media screen and (max-width: 480px) {
  .mat-step-label {
    font-size: 14px;
  }
  .icon-token-oda,
  .icon-token-otp {
    .mat-form-field-wrapper {
      margin-top: 15px;
      width: 100% !important;
    }
  }
  .icon-token {
    .mat-form-field-wrapper {
      width: 100% !important;
    }
  }
}

.icon-tooltip {
  font-family: "Open Sans";
  font-size: 16px !important;
  letter-spacing: -0.3px;
  line-height: 24px;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87) !important;
}

.bancolombia-theme .mat-option.mat-active {
  color: rgba(0, 0, 0, 0.87) !important;
}
.mat-button-focus-overlay {
  background-color: transparent !important;
}
.btn-edit {
  i,
  svg {
    color: rgba(0, 0, 0, 0.87);
    background: transparent !important;
  }
  .mat-button,
  .mat-icon-button,
  .mat-stroked-button {
    color: inherit;
    background: transparent !important;
  }
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: #d1d3d4;
}

.cdk-visually-hidden {
  display: none;
}

.mat-expansion-panel-header-title {
  margin-top: 5px;
}

.icon-token {
  .mat-form-field-infix {
    width: 189px;
    margin-left: 35px;
  }
  .mat-form-field-wrapper {
    width: 50%;
  }
}
.icon-token-oda {
  .mat-form-field-infix {
    width: 189px;
  }
  .mat-form-field-wrapper {
    width: 50%;
  }
}

.icon-token-otp {
  .mat-form-field-infix {
    width: 300px;
  }
  .mat-form-field-wrapper {
    width: 50%;
  }
}

.label-cf{
  .mat-form-field-label{
    font-size: 19px !important;
  }
}

.mat-checkbox-layout{
  white-space: initial;
}

.mat-checkbox-inner-container {
  margin: inherit;
  vertical-align: initial;
  margin-right: 5px;
}

.debitCard{
  .mat-radio-label{
    white-space: inherit;
  }
}